import { defineStore } from "pinia";
import type { PostHog } from "posthog-js";

import type { PostHogInfo } from "@/types/posthog";
interface PostHogStore {
  _featureFlags?: Record<string, string>;
  _distinctId?: string;
  _sessionId?: string;
  _userId?: string;
  _posthog?: PostHog;
  _skipFeatureFlagUpdate: number;
}

export const usePostHogStore = defineStore("postHogStore", {
  state: (): PostHogStore => ({
    _featureFlags: undefined,
    _distinctId: undefined,
    _sessionId: undefined,
    _userId: undefined,
    _posthog: undefined,
    _skipFeatureFlagUpdate: 0
  }),
  actions: {
    initialize(instance: PostHog) {
      this._posthog = instance;
      this._posthog.onFeatureFlags((flags) => {
        // Skip feature flag update if we are in the middle of an identify call
        // There is a bug in posthog where the feature flags are crap right after an identify call
        // Subsequent onFeatureFlags calls will have the correct feature flags
        if (this._skipFeatureFlagUpdate == 0) {
          this._featureFlags = flags.reduce((result, flag) => {
            const flagValue = this._posthog?.getFeatureFlag(flag);
            if (typeof flagValue === "string") {
              result[flag] = flagValue;
            } else {
              result[flag] = flagValue ? "true" : "false";
            }
            return result;
          }, {});
        }

        this._distinctId = this._posthog?.get_distinct_id();
        this._sessionId = this._posthog?.get_session_id();
        this._userId = this._posthog?.get_distinct_id();
        this._skipFeatureFlagUpdate = 0;
      });
    },
    setSessionId(sessionId: string) {
      this._sessionId = sessionId;
    },
    identify(id: string, email: string) {
      if (this._posthog && this._distinctId !== id) {
        this._skipFeatureFlagUpdate++;
        this._posthog.identify(id, { email });
        this._distinctId = id;
      }
    },
    refreshFeatureFlags() {
      if (this._posthog) {
        this._posthog.reloadFeatureFlags();
      }
    },
    pageLeaveEvent(url: string) {
      if (this._posthog) {
        this._posthog.capture("$pageleave", { $current_url: url });
      }
    },
    pageViewEvent(url: string) {
      if (this._posthog) {
        this._posthog.capture("$pageview", { $current_url: url });
      }
    },
    reset() {
      this._distinctId = undefined;
      this._sessionId = undefined;
      this._userId = undefined;
      if (this._posthog) {
        this._posthog.reset();
      }
    }
  },
  getters: {
    getTrackingData(): PostHogInfo {
      return {
        feature_flags: this._featureFlags,
        distinct_id: this._distinctId,
        session_id: this._sessionId,
        user_id: this._userId
      };
    },
    getFutureFlag(): (flag: string) => string | undefined {
      return (flag: string) => {
        if (this._featureFlags) {
          const flagValue = this._featureFlags[flag];
          if (typeof flagValue === "string") {
            return flagValue;
          }
        }
        return undefined;
      };
    }
  }
});
