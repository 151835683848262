import { UserAPI, UserProfileAPI, UserUtilsAPI } from "@/api";
import { NO_AUTH_HEADER } from "@/config/constants";
import type {
  CommonProductFocus,
  DtoBusinessCodeResponse,
  DtoCartItem,
  DtoChangeEmailIntentRequest,
  DtoChangeEmailRequest,
  DtoChangePasswordRequest,
  DtoChangePhoneRequest,
  DtoChangeUsernameRequest,
  DtoCouponDetails,
  DtoForgotPasswordRequest,
  DtoLoginResponse,
  DtoUserProfile,
  DtoUserSettingsResponse,
  DtoZipResponse,
  UserAddPhoneRequest,
  UserConsentRequest,
  UserContactUsRequest,
  UserSetPasswordRequest,
  UserTrafficSourceRequest,
  UtilsArticle,
  UtilsEmailValidationResponse
} from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: User");
const zipCache: Record<string, DtoZipResponse> = {};

export const setTrafficSource = async (request: UserTrafficSourceRequest): Promise<string> => {
  log.time("Setting Traffic Source");

  const { data } = await UserProfileAPI.setTrafficSource(request);

  log.timeEnd("Setting Traffic Source");

  return data;
};

export const contactSearch = async (q: string): Promise<UtilsArticle[]> => {
  log.time("Getting Contact us");
  const options = {
    headers: {
      "X-Skip-Loading-Indicator": true,
      "X-No-Auth": true
    }
  };

  const { data } = await UserProfileAPI.contactSearch(q, options);

  log.timeEnd("Getting Contact us");

  return data;
};

export const contactUs = async (request: UserContactUsRequest) => {
  log.time("Getting Contact us");

  const { data } = await UserProfileAPI.contactUs(request);

  log.timeEnd("Getting Contact us");

  return data;
};

export const updateUsersRedClickId = async (clickId: string, userId: string) => {
  await UserProfileAPI.storeRedtrackData(
    {
      click_id: clickId,
      user_id: userId
    },
    NO_AUTH_HEADER
  );
};

export const ConsentTermsAndConditions = async (params: UserConsentRequest): Promise<DtoLoginResponse> => {
  log.time("Starting consent terms and conditions");

  const { data } = await UserProfileAPI.usersConsentPost(params);

  log.timeEnd("Starting consent terms and conditions");

  return data;
};

export const ValidateZip = async (zip: string): Promise<DtoZipResponse> => {
  const options = {
    headers: {
      "No-Auth": true
    }
  };

  log.time("Validate zip call");

  if (zip in zipCache) {
    log.info("Zip cache hit", zip, zipCache[zip]);
    log.timeEnd("Validate zip call");

    return Promise.resolve(zipCache[zip]);
  }

  log.info("Zip cache miss", zip);

  const { data } = await UserUtilsAPI.validateZip(zip, options);

  zipCache[zip] = data;

  log.timeEnd("Validate zip call");

  return Promise.resolve(data);
};

export const GetClientIp = async (): Promise<string> => {
  log.time("Get client ip call");

  const { data } = await UserUtilsAPI.getClientIp(NO_AUTH_HEADER);

  log.timeEnd("Get client ip call");
  return data;
};

export const ExpandUrl = async (code: string): Promise<string> => {
  log.time("Login call");

  const { data } = await UserUtilsAPI.expandURL(code, NO_AUTH_HEADER);

  log.timeEnd("Login call");

  return data;
};

export const EmailConfirm = async (params: DtoChangeEmailRequest) => {
  log.time("Email confirm call");

  const { data } = await UserProfileAPI.changeUserEmail(params);

  log.timeEnd("Email confirm call");

  return data;
};

export const PasswordReset = async (params: DtoForgotPasswordRequest) => {
  log.time("Password reset call");

  const { data } = await UserProfileAPI.forgotPassword(params, NO_AUTH_HEADER);

  log.timeEnd("Password reset call");

  return data;
};

export const ConfigurePassword = async (params: UserSetPasswordRequest) => {
  log.time("Adding password to account");

  const { data } = await UserProfileAPI.configurePassword(params);

  log.timeEnd("Adding password call");

  return data;
};

export const UpdateUsername = async (params: DtoChangeUsernameRequest) => {
  log.time("Update username call");

  const { data } = await UserProfileAPI.changeUsername(params);

  log.timeEnd("Update username call");

  return data;
};

export const UpdateEmail = async (params: DtoChangeEmailIntentRequest) => {
  log.time("Update email call");

  const { data } = await UserProfileAPI.changeUserMailIntent(params);

  log.timeEnd("Update email call");

  return data;
};

export const UpdatePhone = async (params: DtoChangePhoneRequest) => {
  log.time("Update phone");

  const { data } = await UserProfileAPI.changeUserPhone(params);

  log.timeEnd("Update phone");

  return data;
};

export const UpdatePassword = async (params: DtoChangePasswordRequest) => {
  log.time("Update password");

  const { data } = await UserProfileAPI.changeUserPassword(params);

  log.timeEnd("Update password");

  return data;
};

export const RecoverPassword = async (email: string) => {
  log.time("Update recover password");

  const { data } = await UserProfileAPI.forgotPasswordIntent(
    {
      email
    },
    NO_AUTH_HEADER
  );

  log.timeEnd("Update recover password");
  return data;
};

export const RecoverUsername = async (email: string): Promise<DtoUserProfile> => {
  log.time("Update recover username");

  const { data } = await UserProfileAPI.forgotUsername(
    {
      email
    },
    NO_AUTH_HEADER
  );

  log.timeEnd("Update recover username");
  return data;
};

//Payments

export const TrackCheckoutVisit = async () => {
  try {
    await UserAPI.reachedCheckout();
  } catch (e) {
    console.error(e);
  }
};

export const TrackPricingVisit = async () => {
  try {
    await UserAPI.reachedPricing();
  } catch (e) {
    console.error(e);
  }
};

export const GetCouponDetails = async (coupon: string): Promise<DtoCouponDetails> => {
  log.time("Coupon details call");

  const { data } = await UserProfileAPI.getCouponDetails(coupon);

  log.timeEnd("Coupon details call");

  return data;
};

export const ResetProductFocus = async (value: CommonProductFocus) => {
  log.time("Resetting product focus");

  const { data } = await UserProfileAPI.resetProductFocus({ product_focus: value });

  log.timeEnd("Product focus reset");

  return data;
};

export const GetBusinessCode = async (businessName: string): Promise<DtoBusinessCodeResponse> => {
  log.time("Business form GET Code");

  const { data } = await UserUtilsAPI.companyNameToBusinessCode(businessName, NO_AUTH_HEADER);

  log.timeEnd("Business form GET Code");
  return data;
};

export const GetSettings = async (
  redtrackId: string,
  funnel?: string,
  formId?: string,
  cartPurchases?: DtoCartItem[],
  purchases?: string[]
): Promise<DtoUserSettingsResponse> => {
  log.time("Get settings call");

  const { data } = await UserProfileAPI.getSettings(
    funnel,
    formId,
    cartPurchases?.map((x) => x.sku),
    redtrackId,
    purchases
  );

  log.timeEnd("Get settings call");

  return data;
};

export const ApplyCoupon = async (coupon: string): Promise<DtoCouponDetails> => {
  log.time("Apply coupon call");

  const { data } = await UserProfileAPI.applyCoupon(coupon);

  log.timeEnd("Apply coupon call");

  return data;
};

export const ValidateEmail = async (email: string): Promise<UtilsEmailValidationResponse> => {
  log.time("Validate email call");

  const { data } = await UserUtilsAPI.validateEmail({ email }, NO_AUTH_HEADER);

  log.timeEnd("Validate email call");

  return data;
};

export const ValidatePhone = async (phone: string): Promise<string> => {
  log.time("Validate phone call");

  const { data } = await UserUtilsAPI.validatePhone({ phone }, NO_AUTH_HEADER);

  log.timeEnd("Validate phone call");

  return data;
};

export const ValidateUsername = async (username: string): Promise<string> => {
  log.time("Validate username call");

  const { data } = await UserUtilsAPI.validateUsername(username, NO_AUTH_HEADER);

  log.timeEnd("Validate username call");

  return data;
};

export const addUserPhone = async (request: UserAddPhoneRequest): Promise<DtoUserProfile> => {
  log.time("Add user phone call");

  const { data } = await UserProfileAPI.addUserPhone(request);

  log.timeEnd("Add user phone call");

  return data;
};
