import { BoirAPI } from "@/api";
import type { BackendAppControllerBoirForm, BoirAvailableBoirFormCountResponse } from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: BOIR");

export const getAllBoirForms = async (): Promise<BackendAppControllerBoirForm[]> => {
  log.time("Getting all boir forms");

  const { data } = await BoirAPI.getAllBOIRForms();

  log.timeEnd("Getting all boir forms");

  return data;
};

export const deleteBoirForm = async (id: string): Promise<void> => {
  log.time("Deleting boir form");

  await BoirAPI.deleteBoirForm(id);

  log.timeEnd("Deleting boir form");
};

export const getAvailableBoirFormCount = async (): Promise<BoirAvailableBoirFormCountResponse> => {
  log.time("Getting available boir form count");

  const { data } = await BoirAPI.getAvailableBoirFormCount();

  log.timeEnd("Getting available boir form count");

  return data;
};
