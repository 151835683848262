import { useFormsStore } from "@/store/forms.store";
import type { OpenPymentModalData } from "@/types/dashboard";

import { useEventBus } from "./use-event-bus";

export const useModalsState = () => {
  const eventBus = useEventBus();

  const openOfferModal = (): void => {
    eventBus.$emit("setShowOfferModal", { to: true });
  };

  const openPaymentModal = ({
    preventCloseModals = false,
    offerReactivateSubscription = false,
    showDiy = false
  }: OpenPymentModalData): void => {
    eventBus.$emit("setShowPaymentInfoModal", {
      to: true,
      preventCloseModals,
      offerReactivateSubscription,
      showDiy
    });
  };

  const closePaymentModal = async (): Promise<void> => {
    const formsStore = useFormsStore();

    eventBus.$emit("setShowPaymentInfoModal", { to: false, preventCloseModals: true });

    await formsStore.getDfyReturns();
  };

  const openOrderSuccessModal = (source: string): void => {
    eventBus.$emit("setShowOrderSuccessModal", { to: true, source });
  };

  const closeOrderSuccessModal = (): void => {
    eventBus.$emit("setShowOrderSuccessModal", { to: false });
  };

  const closeAllModals = (): void => {
    eventBus.$emit("closeAllModals");
  };

  const modalsState = {
    openOfferModal,
    openPaymentModal,
    closePaymentModal,
    openOrderSuccessModal,
    closeOrderSuccessModal,
    closeAllModals
  };

  return { modalsState };
};
