import type { SetupIntent, StripeError } from "@stripe/stripe-js";
import type { JwtPayload } from "jwt-decode";

import type {
  BackendAppControllerBoirForm,
  DfyTaxReturn,
  DtoDeadline,
  DtoForm4868,
  DtoForm7004,
  DtoUserProfile
} from "@/services/api";
import type { DiyTaxReturn } from "@/services/api";

export type ExtForm = DtoForm4868 | DtoForm7004;

export type FilingCard = ExtForm | DfyTaxReturn | DiyTaxReturn | BackendAppControllerBoirForm;

export type Filing = FilingCard & {
  priority: FilingPriority;
};

export const isForm4868 = (form: FilingCard): form is DtoForm4868 => {
  return (form as DtoForm4868).first_name !== undefined;
};

export const isForm7004 = (form: FilingCard): form is DtoForm7004 => {
  return (form as DtoForm7004).business_type !== undefined;
};

export const isFormDiyReturn = (form: FilingCard): form is DiyTaxReturn => {
  return (form as DiyTaxReturn).jurisdiction !== undefined;
};

export const isBoirForm = (form: FilingCard): form is BackendAppControllerBoirForm => {
  return (form as BackendAppControllerBoirForm).completion_status !== undefined;
};

export interface UserData extends DtoUserProfile {
  source: string;
}

export interface CurrentForm {
  uid: string | null;
  type: "personal" | "business";
  both: boolean;
  boost: boolean;
  autoRenew: boolean;
  processedProgress: number;
  processedLoopingProgress: number;
  processedTarget: number;
}

export interface PosthogFeatureFlags {
  funnelValue?: string;
}

export interface ExtJwtPayload extends Required<JwtPayload> {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  nonce: string;
  num: string;
  role: string;
  has_password: boolean;
  scopes: Array<"consent" | "client" | "triage" | "admin">;
}

export interface DtoDeadlines {
  Form1041?: DtoDeadline;
  Form1065?: DtoDeadline;
  Form1120?: DtoDeadline;
  Form1120F?: DtoDeadline;
  Form1120S?: DtoDeadline;
  Form4868?: DtoDeadline;
}

export enum DtoPurchaseSkuEnum {
  Pr01 = "pr01",
  Pr02 = "pr02",
  Pr03 = "pr03",
  Pr04 = "pr04",
  Pr05 = "pr05",
  Pr07 = "pr07",
  Pr08 = "pr08",
  Pr09 = "pr09",
  Pr10 = "pr10",
  Pr11 = "pr11",
  Pr12 = "pr12",
  Pr13 = "pr13",
  Pr14 = "pr14",
  Pr15 = "pr15",
  Pr16 = "pr16",
  Pr17 = "pr17",
  Pr18 = "pr18",
  Pr19 = "pr19",
  Pr20 = "pr20",
  Pr21 = "pr21",
  Pr22 = "pr22",
  Pr23 = "pr23",
  Pr24 = "pr24",
  Pr25 = "pr25",
  Pr26 = "pr26",
  Pr27 = "pr27",
  Pr28 = "pr28",
  Pr29 = "pr29",
  Pr30 = "pr30",
  Pr31 = "pr31",
  Pr40 = "pr40",
  Pr48 = "pr48",
  Pr69 = "pr69",
  Pr70 = "pr70",
  Pr71 = "pr71",
  Pr99 = "pr99"
}

export enum FormType {
  personal = "Personal",
  business = "Business",
  diyReturn = "DiyReturn",
  boir = "Boir"
}

export enum PosthogSurveyIDs {
  PleaseCancel = "018ec29e-5aa0-0000-39ad-d515648b6c61",
  CancelReason = "018ec2a2-a908-0000-ff9b-a823bf6458f9"
}

export const cancellationViews = Object.freeze({
  StandartView: "StandartView",
  DiyView: "DiyView",
  DfyView: "DfyView"
});

export enum PosthogPleaseCancelAnswers {
  FileMyself = "File myself",
  WorkWithTaxPreparer = "Work with a tax preparer"
}

export enum FilingCardTypes {
  Extension = "extension",
  DiyReturn = "diy-return",
  DfyReturn = "dfy-return"
}

export enum FilingCardPrimaryAction {
  Offer = "offer",
  OfferModal = "offer modal",
  DfyBooking = "dfy booking"
}

export enum FilingCardState {
  Alert = "alert",
  Premium = "premium"
}

export enum NewFilingModalState {
  Empty = "empty",
  Choose = "choose",
  VerifyPassword = "verify-password",
  VerifyPassword2FA = "verify-password-2fa",
  CreatePassword = "create-password",
  VerifyPhone = "verify-phone",
  Redirect = "redirect"
}

export enum FilingPriority {
  High = "high",
  Low = "low"
}

export interface CompetitorPrice {
  code: string;
  display_name: string;
  price: number;
}

export type Competitor = "tax-slayer" | "hr-block" | "tax-act" | "turbo-tax";

export type ShowHeaderMenu = "always" | "never" | "not-logged";

export interface StipePaymentDefaults {
  submitError?: StripeError;
  setupIntent?: SetupIntent;
}
